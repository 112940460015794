import {Position} from './Position.js'
import {PositionSequenceRightDown} from './PositionSequenceRightDown.js'
import {PositionSequence} from './PositionSequence.js'
import {PositionSequenceDiagonal} from './PositionSequenceDiagonal.js'
import {PositionSequenceRotate} from './PositionSequenceRotate.js'
import {Entry} from './Entry.js'
import {Cw} from './Cw.js'

export function extractWords(text) {
	let words =
		text.toUpperCase().split(/[ .,;:?!@%&\/–='"*(){}+-]|\uFF0C|\u3002|\n|\\./);
	let extractedWords =
		[...filterWords(words
		.filter(w => w.length > 1))]
		.sort(function (a, b) {
			if (a.length > b.length) return -1;
			return 1
		});
	return extractedWords;
}

export function filterWords(words) {
	const boring = [
		"EN",
		"ETT",
		"DEN",
		"DET",
		"OCH",
		"SKA",
		"SKALL",
		"FÖR",
		"MED",
		"VAR",
		"TILL",
		"FRÅN",
		"BLI",
		"BLEV"
	];
	return words.filter(word => !boring.includes(word))
		.reduce((set, word) => set.add(word), new Set());
}

export class Weave {
	constructor(text) {
		let words = extractWords(text);
		this.allWords = [...words];
		this.usedWords = new Set();
	}

	weaveWordsToJson() {
		let wovenWords = this.weaveWords(PositionSequenceRightDown);
		let matrix = wovenWords.map(row => row.map(model => new Entry(model)));
		let legendwords = Array.from(this.usedWords);
		return new Cw(this.allWords[0], matrix, this.createLegend(legendwords));
	}

	weaveWords(positionSequenceClass) {
		let matrix = this.createMatrix(Math.max(this.minimumMatrixSize(), this.estimateMatrixSize()));
		this.braidWords(matrix, this.allWords, positionSequenceClass);
		return matrix;
	}

	minimumMatrixSize() {
		return Math.max(...this.allWords.map(word => word.length), 10);
	}

	estimateMatrixSize() {
		return Math.max(8, Math.round(Math.sqrt(this.allWords.length * 5)));
	}

	createMatrix(size) {
		return Array(size).fill().map(() => Array(size).fill(''));
	}

	braidWords(matrix, words, positonSequenceKClass) {
		var startCol = Math.floor((matrix[0].length - 1) / 2);
		var nextVertical = false;
		let row = Math.max(Math.min(matrix.length - words[0].length, 2), 0);
		this.insertWord(words[0], matrix, new Position(row, startCol), !nextVertical);
		for (var allowIsolated of [false]) {
			for (var _i = 0, words_1 = words; _i < words_1.length; _i++) {
				var it = words_1[_i];
				if (!this.usedWords.has(it)) {
					if (!this.insertIfItFits(it, matrix, nextVertical, positonSequenceKClass, allowIsolated)) {
						nextVertical = !nextVertical;
						this.insertIfItFits(it, matrix, nextVertical, positonSequenceKClass, allowIsolated);
					}
					nextVertical = !nextVertical;
				}
			}
		}
	}

	insertIfItFits(word, matrix, vertical, kClass, allowIsolated) {
		// var primaryConstructor = kClass.primaryConstructor;
		// var sequence = primaryConstructor.call(matrix.length, matrix[0].length, word.length);
		var sequence = new PositionSequenceRightDown(matrix.length, matrix[0].length, word.length);
		var pos = sequence.nextPosition();
		while (pos != null) {
			if ((vertical && this.fitsVertically(word, matrix, pos, allowIsolated)) || (!vertical && this.fitsHorizontally(word, matrix, pos, allowIsolated))) {
				this.insertWord(word, matrix, pos, vertical);
				return true;
			}
			pos = sequence.nextPosition();
		}
		return false;
	}

	insertWord(word, matrix, pos, vertical) {
		var ix = 0;
		for (var i = 0; i < word.length; i++) {
			if (vertical) {
				matrix[pos.row + ix][pos.col] = word.charAt(i);
			} else {
				matrix[pos.row][pos.col + ix] = word.charAt(i);
			}
			ix++;
		}
		this.usedWords.add(word);
	}

	fitsVertically(word, matrix, pos, allowIsolated) {
		if (!this.freeAboveAndBelow(matrix, pos, word.length)) return false;
		var ix = 0;
		var crossing = false;
		var allCross = [];
		for (var i = 0; i < word.length; i++) {
			if (pos.row + ix >= matrix.length ||
				!(matrix[pos.row + ix][pos.col] === '' || matrix[pos.row + ix][pos.col] === (word.charAt(i)).toString())
			) return false;
			if (!emptyLeftAndRight(matrix, pos.row + ix, pos.col)) {
				var cross = getHorizontalWordAround(matrix, pos.row + ix, pos.col, word.charAt(i));
				if (!this.allWords.includes(cross) || this.usedWords.has(cross))
					return false;
				allCross.push(cross);
			}
			if (matrix[pos.row + ix][pos.col].length > 0) crossing = true;
			ix++;
		}
		allCross.forEach(this.usedWords.add, this.usedWords);
		return allowIsolated || crossing;
	}

	fitsHorizontally(word, matrix, pos, allowIsolated) {
		if (!this.freeLeftAndRight(matrix, pos, word.length)) return false;
		var ix = 0;
		var crossing = false;
		var allCross = [];
		for (var i = 0; i < word.length; i++) {
			if (pos.col + ix >= matrix[pos.row].length ||
				!(matrix[pos.row][pos.col + ix] === '' || matrix[pos.row][pos.col + ix] === word.charAt(i))
			) return false;
			if (!emptyAboveAndBelow(matrix, pos.row, pos.col + ix)) {
				var cross = getVerticalWordAround(matrix, pos.row, pos.col + ix, word.charAt(i));
				if (!this.allWords.includes(cross) || this.usedWords.has(cross))
					return false;
				allCross.push(cross);
			}
			if (matrix[pos.row][pos.col + ix].length > 0)
				crossing = true;
			ix++;
		};
		allCross.forEach(this.usedWords.add, this.usedWords);
		return allowIsolated || crossing;
	}

	sameOrEmpty(s, it) {
		return s === '' || s === it.toString();
	}

	freeAboveAndBelow(matrix, pos, len) {
		return (pos.row <= 0 || matrix[pos.row - 1][pos.col] === '') &&
			(pos.row + len + 1 >= matrix.length || matrix[pos.row + len][pos.col] === '');
	}

	freeLeftAndRight(matrix, pos, len) {
		return (pos.col <= 0 || matrix[pos.row][pos.col - 1] === '') &&
			(pos.col + len >= matrix[pos.row].length || matrix[pos.row][pos.col + len] === '');
	}

	createLegend(usedW) {
		return Array.from(usedW).join(' ');
	}

}

function getVerticalWordAround(matrix, row, col, l) {
	var start = row;
	var end = row;
	while (start >= 0 && (start == row || matrix[start][col].length > 0))
		start--;
	start++;
	while (end < matrix.length && (end == row || matrix[end][col].length > 0))
		end++;
	end--;
	var verticalWord = [];
	verticalWord.fill(' ', 0, end - start + 1);
	for (var ix = start; ix <= end; ix++) {
		verticalWord[ix - start] = (ix == row) ? l : matrix[ix][col][0];
	}
}

function getHorizontalWordAround(matrix, row, col, l) {
	var start = col;
	var end = col;
	while (start >= 0 && (start == col || matrix[row][start].length > 0)) start--;
	start++;
	while (end < matrix[row].length && (end == col || matrix[row][end].length > 0)) end++;
	end--;
	var horizontalWord = [];
	horizontalWord.fill(' ', 0, end - start + 1);
	for (var ix = start; ix <= end; ix++) {
		horizontalWord[ix - start] = (ix == col) ? l : matrix[row][ix][0];
	}
	return horizontalWord.join("");
}

function emptyAboveAndBelow(matrix, row, col) {
	return matrix[row][col].length > 0 ||
		((row <= 0 || matrix[row - 1][col].length == 0) &&
			(row >= matrix.length - 1 || matrix[row + 1][col].length == 0));
}

function emptyLeftAndRight(matrix, row, col) {
	return matrix[row][col].length > 0 ||
		((col <= 0 || matrix[row][col - 1].length == 0) &&
			(col >= matrix[row].length - 1 || matrix[row][col + 1].length == 0));
}

export function listWords(matrix) {
	var list = [];

	function addToList(m) {
		let w = ""
		return m.forEach(b => {
			if (b && b.model.length > 0) w = w.concat(b.model)
			else { if (w.length > 1) list.push(w); w =""; }
		});
	}

	function fromRows(matrix) {
		matrix.forEach(addToList)
	}
	function fromColumns(matrix) {
		var column = [];
		for (var ix = 0; ix < matrix[0].length; ix++) {
			matrix.forEach(r => {
				column.push(r[ix])
			})
			addToList(column);
			column = []
		}
	}

	fromRows(matrix);
	fromColumns(matrix);
	return list;
}
