import React from 'react';
import './fabric/Weave';
import {listWords, Weave} from './fabric/Weave.js';
import './Spellbind.css';

const spellfoundUrl = 'http://spellfound.s3-website.eu-north-1.amazonaws.com/puzzles/'

const spellstoreUrl = 'https://spellfound.s3.eu-north-1.amazonaws.com/puzzles/'

class Spellbind extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			letters : {},
			facit: [],
			matrix : [],
			words: "WORD WIDE web",
			typeDown : false,
			outputfilnamn : "spell.json",
			density : 0,
			puzzleLink : spellfoundUrl,
			puzzle : props.puzzle,
			spellweaverUrl : "https://9bgp9o682b.execute-api.eu-north-1.amazonaws.com/default/spellweaver"
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleArrowKey = this.handleArrowKey.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.handleBuildRequest = this.handleBuildRequest.bind(this);
		this.handleInternalBuildRequest = this.handleInternalBuildRequest.bind(this);
		this.loadJson = this.loadJson.bind(this);
		this.handleTitleChange = this.handleTitleChange.bind(this);
		this.handleLegendChange = this.handleLegendChange.bind(this);
		this.handleWordsChange = this.handleWordsChange.bind(this);
		this.trimMatrix = this.trimMatrix.bind(this);
		this.isEmptyRow = this.isEmptyRow.bind(this);
		this.uploadPuzzle = this.uploadPuzzle.bind(this);
		this.handleSpellweaverUrlChange = this.handleSpellweaverUrlChange.bind(this);
		this.handleCreateLegend = this.handleCreateLegend.bind(this);
	}

	buildFacit() {
		this.state.facit = [];
		this.state.letters = [];
		let idx = 0;
		this.state.matrix.map(r => {r.map(c => {
			let model = c.model.toUpperCase();
			if (!this.state.letters.includes(model)) {
				this.state.facit[idx++] = {"model" : model, "value" : model}
				this.state.letters.push(model);
			}
		})});
	}

	handleChange(e) {
		if (e !== undefined) {
			let upperCase = e.target.value.toUpperCase();
			this.setState({
				[e.target.id] : upperCase
			});
			this.state.matrix[parseInt(e.target.id.toString().substr(1,2))][parseInt(e.target.id.toString().substr(3,2))].model = upperCase;
			if (this.state.typeDown) {
				this.moveFocus(this.nextIdDown(e.target.id));
			} else {
				this.moveFocus(this.nextIdRight(e.target.id));
			}
			this.calculateDensity(this.state.matrix);
		}
	}

	calculateDensity(matrix) {
		while (this.isEmptyRow(matrix[matrix.length - 1])) {
			matrix = matrix.slice(0, matrix.length - 1);
		}
		let maxRowLength = 0;
		matrix.map(r => {if (r.length > maxRowLength) maxRowLength = r.length});

		var size = matrix.length * maxRowLength;
		var nrofChars = 0;
		matrix.map(r => {r.map(c => {if (c.model != null && c.model.length > 0) nrofChars++; })})
		this.setState({density : Math.round((nrofChars * 100) / size)});
	}

	handleTitleChange(e)
	{
		this.setState({title : e.target.value});
	}
	handleLegendChange(e)
	{
		this.setState({legend : e.target.value});
	}
	handleWordsChange(e)
	{
		this.setState({words : e.target.value});
	}
	handleSpellweaverUrlChange(e) {
		this.setState({spellweaverUrl : e.target.value});
	}

	moveFocus(nextid) {
		if (document.getElementById(nextid) !== null) {
			document.getElementById(nextid).focus();
		}
	}

	nextIdRight(id) {
		return id.substr(0, 3) + (parseInt(id.substr(3, 2), 10) + 1).toString().padStart(2, "0");
	}
	nextIdLeft(id) {
		return id.substr(0, 3) + (parseInt(id.substr(3, 2), 10) - 1).toString().padStart(2, "0");
	}
	nextIdUp(id) {
		return id.substr(0, 1) + (parseInt(id.substr(1, 2), 10) - 1).toString().padStart(2, "0") + id.substr(3, 2);
	}
	nextIdDown(id) {
		return id.substr(0, 1) + (parseInt(id.substr(1, 2), 10) + 1).toString().padStart(2, "0") + id.substr(3, 2);
	}

	handleArrowKey (e) {
		var nextid;
		switch (e.key) {
			case 'ArrowUp':
				nextid = this.nextIdUp(e.target.id);
				this.setState({typeDown : true});
				break;
			case 'ArrowDown':
				nextid = this.nextIdDown(e.target.id);
				this.setState({typeDown : true});
				break;
			case 'ArrowLeft':
				nextid = this.nextIdLeft(e.target.id);
				this.setState({typeDown : false});
				break;
			case 'ArrowRight':
				nextid = this.nextIdRight(e.target.id);
				this.setState({typeDown : false});
				break;
		}
		this.moveFocus(nextid);
	};

	handleFileSelect(evt) {
		if (evt) {
			let files = evt.target.files;
			if (!files.length) {
				alert('No file select');
				return;
			}
			let file = files[0];
			let reader = new FileReader();
			let that = this;
			reader.onload = function (e) {
				that.loadJson(JSON.parse(e.target.result));
			};
			reader.readAsText(file);
		}
	}

	handleCreateLegend(evt) {
		if (evt) {
			let that = this;
			return that.setState({legend : "<b>" + listWords(that.state.matrix).join("</b><br/> <b>") + "</b>"})
		}
	}

	handleInternalBuildRequest(evt) {
		if (evt) {
			let that = this;
			return that.loadJson(
			new Weave(that.state.words).weaveWordsToJson()
			)
		}
	}
	handleBuildRequest(evt) {
		if (evt) {
			let that = this;
			return fetch(this.state.spellweaverUrl, {
				method : 'POST',
				headers : {'Content-Type' : 'application/json'},
				body : '{"message": "' + that.state.words.replace(/\n/gi, '\\\\n') + '"}'
			})
			.then(response => response.json())
			.then(
				out =>	that.loadJson(out)
			)
			.catch((error) => {
				alert("Tjänstefel: " + error);
				console.error(error)
			})
		}
	}

	loadJson(json) {
		this.setState({matrix : json.matrix, facit : json.facit, title : json.title, legend : this.decorateLegend(json.legend), density : 0});
		this.calculateDensity(json.matrix);
	}

	decorateLegend(legend) {
		let ws = legend.split(" ");
		legend = ' ' + this.state.words + ' ';
		legend = legend.replace(/\n/gi, ' <br/> ');

		let addMarkerFunction = function addMarker(w) {
			let len = w.length
			return w.substring(0, 1) + "<b>" + w.substring(1, len - 1) + "</b>" + w.substring(len - 1, len)
		}

		function wordRegExp(w) {
			return new RegExp('\\W' + w + '\\W', 'gi');
		}

		ws.map(w => {legend = legend.replace(wordRegExp(w), addMarkerFunction)} )
		return legend;
	}

	handleSubmit(event) {
		this.trimMatrix();
		this.buildFacit();
		let outputfilnamn = document.getElementById("outputfilnamn");
		this.saveData(this.state, outputfilnamn.value);
		event.preventDefault();
	}

	trimMatrix() {
		while (this.isEmptyRow(this.state.matrix[this.state.matrix.length - 1])) {
			this.state.matrix = this.state.matrix.slice(0, this.state.matrix.length - 1);
		}

		var idx;
		for (idx = this.state.matrix.length - 1; idx >= 0; idx--) {
			this.trimEnd(this.state.matrix[idx], idx);
		}
	}

	isEmptyRow(row) {
		return row && row.filter(c => this.hasValue(c)).length === 0;
	}

	hasValue(cell) {
		return cell && cell.model && cell.model.length > 0;
	}

	trimEnd(row, index) {
		let ix = row.length;
		while (ix > 1 && !this.hasValue(row[ix - 1])) {
			ix--;
		}
		this.state.matrix[index] = row.slice(0, ix);
	}

	saveData(data, outputfilnamn) {
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";

		var json = JSON.stringify(data),
			blob = new Blob([json], {type: "text/plain;charset=utf-8"}),
			url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = outputfilnamn;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	getUploadURL() {
		return spellstoreUrl + this.state.title + '.json'
	}

	async uploadPuzzle() {
		let that = this;
		console.log(that.state);
		this.trimMatrix();
		this.buildFacit();
		this.getUrlToUploaded();
		return fetch(this.getUploadURL(), {
			method : 'PUT',
			headers : {'Content-Type' : 'application/json'},
			body : JSON.stringify(this.state)
		})
		.then(response => {
			if (!response.ok) {
				throw new Error("Pusselfel: " + response.statusText);
			}
		})
		.catch((error) => {
			alert("Tjänstefel i pussel: " + error);
			console.error(error)
		})
	}

	getUrlToUploaded() {
			this.setState({puzzleLink: spellfoundUrl + this.state.title})
	}

	handleFocus(e) {
		e.target.select();
	}

	render() {
		var rowId = 0;
		const matrixTab = this.state.matrix.map(rad => {
			var colId = 0;
			++rowId;
			return (<tr key={rowId}>
					{rad.map(cell => {
						let sqrId = "i" + (rowId-1).toString().padStart(2, "0") + colId.toString().padStart(2, "0");
						let sqrClass = "sqr " + ((cell.class !== undefined) ? cell.class : "");
						++colId;
						return (<td key={sqrId}>
							<input id={sqrId} name={sqrId}
										 className={sqrClass} type="text" size="1" maxLength="1"
										 style={{backgroundColor : 'ivory'}}
										 value={cell.model}
										 onChange={this.handleChange} onKeyUp={this.handleArrowKey} onFocus={this.handleFocus}/>
						</td>);
					})}
				</tr>
			)
		})
		return (
			<div className="window">
				<h1 className="Spellbind">Spellbind</h1>
				<div className="title">
					<label htmlFor="inputfilnamn" className="btnLbl">Läs in:&nbsp;</label>
					<input type="file" id="inputfilnamn" name="inputfilnamn" onChange={this.handleFileSelect}/>
				</div>
				<div className="words">
					<label htmlFor="ordlista" className="btnLbl">Underlag:&nbsp;</label>
					<div>
						<textarea id="ordlista" value={this.state.words}
											style={{height : 200, width : 600, verticalAlign : "text-top"}} onChange={this.handleWordsChange}/>
							{/*	<div>
						<input type="button" id="generateButton" value="Extern genererator" className="btn" onClick={this.handleBuildRequest}/>
						<input type="text" value={this.state.spellweaverUrl} size={this.state.spellweaverUrl.length} onChange={this.handleSpellweaverUrlChange}/>
						</div> */}
					</div>
					<div>
						<input type="button" id="generateButton" value="Generera" className="btnLbl" onClick={this.handleInternalBuildRequest}/>
					</div>
				</div>
					<div className="component">
					<form onSubmit={this.handleSubmit}>
						<div className="title">
							<label htmlFor="titel" className="btnLbl">Titel:</label>
							<input type="text" id="titel" value={this.state.title} onChange={this.handleTitleChange}/>
							<input type="button" id="createLegend" value="Skapa legend" className="btnLbl"
										 onClick={this.handleCreateLegend}/>
						</div>
						<table>
							<tbody>
							{matrixTab}
							</tbody>
						</table>
						<div>
							<label className="btnLbl">Densitet:</label><span className="btnLbl">{this.state.density}</span>
						</div>
					</form>
				</div>
				<div className="legend">
					<label htmlFor="legend" className="btnLbl">Legend:</label>
					<textarea value={this.state.legend} id="legend" onChange={this.handleLegendChange} style={{height: 600, width: 350, verticalAlign: "text-top"}}/>
				</div>
				<div className="saveOutput">
					{/*
					<div>
						<input type="button" id="uploadButton" value="Ladda upp" className="btnLbl" onClick={this.uploadPuzzle}/>
						<a id="puzzlelink" href={this.state.puzzleLink}>{this.state.puzzleLink}</a>
					</div>
					*/}
					<div className="verify">
						<label htmlFor="outputfilnamn" className="btnLbl">Outputfilnamn:</label>
						<input type="text" id="outputfilnamn" className="btnLbl" name="outputfilnamn"/>
						<input type="submit" value="Spara" className="btnLbl" onClick={this.handleSubmit}/>
					</div>
			</div>
			</div>
		)
	}

	getDisabled(cell) {
		return cell.model === '';
	}

	componentDidMount() {
		return fetch("./puzzles/frame.json")
		.then(response => response.json())
		.then((json) => {
			this.loadJson(json);
		})
		.catch((error) => {
			console.error(error)
		})
	}

}

export default Spellbind;
