// No equivalent for Kotlin's fun interface in JavaScript
// Define a class instead

class PositionSequence {
    nextPosition() {
        return null;
    }
}

module.exports = PositionSequence;

