import {Position} from './Position.js'

export class PositionSequenceRightDown {
	constructor(height, width, wordLength) {
		this.height = height;
		this.width = width;
		this.pos = new Position(-1, width - 1); // Next will be (0,0)
	}

	nextPosition() {
		if (this.pos.col < this.width - 1) {
			this.pos = new Position(this.pos.row, this.pos.col + 1);
			return this.pos;
		}
		if (this.pos.row < this.height - 1) {
			this.pos = new Position(this.pos.row + 1, 0);
			return this.pos;
		}
		return null;
	}
}

