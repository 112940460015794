import { render } from "react-dom";
import {Route, Routes, BrowserRouter, useLocation} from "react-router-dom";
import './index.css';
import Spellfound from './Spellfound';
import * as serviceWorker from './serviceWorker';
import Spellbind from "./Spellbind";

function Puzzle() {
  const location = useLocation();
  let urlSearchParams = new URLSearchParams(location.search);
  let puzzle = urlSearchParams.get('puzzle');
  let spellbind = urlSearchParams.get('spellbind');
  console.log('function Puzzle - puzzle = ' + puzzle);
  console.log('spellbind = ' + spellbind);
  if (spellbind) {
    return <Spellbind />
  }
  if (!puzzle) {
    window.location.href = '/index_sf.html';
  }

  return <Spellfound puzzle={puzzle}/>;
}

render(
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<Puzzle />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)
/*
render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Spellfound/>
        </Route>
        <Route path="/puzzles/:puzzle">
          <Puzzle/>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
*/
serviceWorker.unregister();
