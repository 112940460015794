export class Cw {
    constructor(title, matrix, legend) {
        this.title = title;
        this.matrix = matrix;
        this.legend = legend;
    }
}

export class Entry {
    constructor(value) {
        this.value = value;
    }
}
